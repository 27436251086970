<template>
  <div class="btns" :style="{ width: btnsWidth + 'px' }">
    <button
      class="btn primary"
      :class="{ thin: saveBtn.length <= 3 }"
      @click="save"
    >
      {{ saveBtn }}
    </button>
    <button
      class="btn secondary"
      v-if="haveCancelBtn"
      :class="{ thin: saveBtn.length <= 3 }"
      @click="cancel"
    >
      {{ cancelBtn }}
    </button>
    <slot></slot>
  </div>
</template>

<script>
/**
 * @module components/btns
 * @desc 速商 表单底部按钮
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-07-07
 * @copyright 2021
 */
export default {
  name: "btns",
  data() {
    return {
      btnsWidth: 0,
    };
  },
  props: {
    saveCallback: {},
    cancelCallback: {},
    saveBtn: {
      type: String,
      default: "保存",
    },
    haveCancelBtn: {
      type: Boolean,
      default: true,
    },
    cancelBtn: {
      type: String,
      default: "取消",
    },
  },
  methods: {
    /**
     * 保存
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-07
     */
    save() {
      this.saveCallback();
    },
    /**
     * 取消按钮
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-07
     */
    cancel() {
      this.cancelCallback();
    },
  },
  mounted() {
    this.btnsWidth = document.body.clientWidth - 160 - 42;
    window.addEventListener(
      "resize",
      () => {
        this.btnsWidth = document.body.clientWidth - 160 - 42;
      },
      false
    );
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
